.file-explorer {
    padding-left: 5px;

    & ul {
        padding-left: 20px;
    }

    & span {
        margin: 0;
        padding-bottom: 0;
    }

    & li {
        list-style: none;
    }

    .preview {
        position: absolute;
        background-color: #303841;
        padding: 3px;
        border: 1px solid #adb5bd;

        img: {
            width: 50px;
        }
    }
}
