/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 3.1.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

//Default
@import "default/_import";

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/horizontal-nav";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/ribbons";
@import "custom/components/print";
@import "custom/components/preloader";

@import "custom/components/modal/_import";



//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/logout";
@import "custom/pages/faq";
@import "custom/pages/maintenance";
@import "custom/pages/timeline";
@import "custom/pages/email";
@import "custom/pages/sitemap";
@import "custom/pages/search-results";
@import "custom/pages/pricing";
@import "custom/pages/gallery";
@import "custom/pages/coming-soon";
@import "custom/pages/profile";
@import "custom/pages/taskboard";
@import "custom/pages/ecommerce";


// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/calendar";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/sweetalert";
@import "custom/plugins/custom-select";
@import "custom/plugins/tippy";
@import "custom/plugins/nestable-list";
@import "custom/plugins/hopscotch";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartjs";
@import "custom/plugins/chartist";
@import "custom/plugins/c3";
@import "custom/plugins/ricksaw";
@import "custom/plugins/responsive-table";
@import "custom/plugins/footables";
@import "custom/plugins/bootstrap-tables";
@import "custom/plugins/tablesaw";
@import "custom/plugins/jsgrid";
@import "custom/plugins/multiple-select";
@import "custom/plugins/autocomplete";
@import "custom/plugins/bootstrap-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/flatpickr";
@import "custom/plugins/clockpicker";
@import "custom/plugins/summernote";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/dropzone";
@import "custom/plugins/dropify";
@import "custom/plugins/x-editable";
@import "custom/plugins/cropper";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";
@import "custom/plugins/mapeal-maps";
@import "~react-circular-progressbar/dist/styles.css";


@import "vendor/custombox.min.css";

@import "custom/matic-custom";
@import "custom/langauge-tables.scss";
@import "custom/file-explorer.scss";