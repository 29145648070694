
$breakpoints: (
    "bp_320": 320px,
    "bp_480": 480px,
    "bp_680": 680px,
    "bp_768": 768px,
    "bp_992": 992px,
    "bp_1024": 1024px,
    "bp_1025": 1025px,
    "bp_1125": 1125px,
    "bp_1240": 1240px,
    "bp_1440": 1440px,
    "bp_1920": 1920px,
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type == max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
