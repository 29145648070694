//
// horizontal-nav.scss
//

// Container width
@include media-breakpoint-up(xl) {
	.container-fluid {
		max-width: $layout-width;
	}
}

// Content start wrapper
.wrapper {
	padding: ($topbar-height + 0px) ($grid-gutter-width / 2) 0 ($grid-gutter-width / 2);
}

// Top header
#topnav {
	background: lighten($bg-topbar-dark, 2%);
	position: fixed;
	left: 0;
	right: 0;
	z-index: 1001;
	padding: 0 ($grid-gutter-width / 2);

	// Menu item arrow
	.arrow-down {
		display: inline-block;

		&:after {
			border-color: initial;
			border-style: solid;
			border-width: 0 0 1px 1px;
			content: "";
			height: 0.4em;
			display: inline-block;
			right: 5px;
			top: 50%;
			margin-left: 5px;
			transform: rotate(-45deg) translateY(-50%);
			transform-origin: top;
			transition: all 0.3s ease-out;
			width: 0.4em;
		}
	}

	// Navbar Toggle
	.navbar-toggle {
		border: 0;
		position: relative;
		padding: 0;
		margin: 0;
		cursor: pointer;

		.lines {
			width: 25px;
			display: block;
			position: relative;
			// margin: 15px 20px 0 20px;
			margin: 15px 12px 0 12px;
			padding-top: 13px;
			height: 44px;
			transition: all 0.5s ease;
		}
		span {
			height: 2px;
			width: 100%;
			background-color: $gray-500;
			display: block;
			margin-bottom: 5px;
			transition: -webkit-transform 0.5s ease;
			transition: transform 0.5s ease;
		}

		&.open {
			span {
				position: absolute;
				&:first-child {
					top: 18px;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					visibility: hidden;
				}
				&:last-child {
					width: 100%;
					top: 18px;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}
	}

	// Active Menu color
	.has-submenu {
		&.active {
			> a {
				color: $white;
			}
			.submenu {
				li.active > a {
					color: $gray-200;
				}
			}
		}
	}

	.topbar-menu {
		margin-top: $topbar-height;
	}
}

.navigation-menu {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		float: left;
		position: relative;

		> a {
			display: block;
			color: rgba($white, 0.7);
			font-size: 15px;
			font-family: $font-family-secondary;
			transition: all 0.5s ease;
			line-height: 20px;
			padding: 25px 20px; /*bl*/
			position: relative;

			i {
				font-size: 15px;
				margin-right: 8px;
			}

			&:hover,
			&:focus,
			&.show {
				background-color: transparent;
			}
		}
	}
}

/*
Responsive Menu
*/

@media (min-width: 700px) {
	.navigation-menu {
		> li {
			&:hover,
			&:focus,
			&:active,
			&.show {
				color: #adb5bd;
				background-color: #414d59;
			}
			> a {
				&:hover,
				&:focus,
				&:active,
				&.show {
					color: #adb5bd;
					background-color: #414d59;
				}
			}
			&:hover,
			&.show {
				a {
					color: #adb5bd;
				}
			}

			// &:first-of-type > a {
			//     padding-left: 0;
			// }

			&.last-elements {
				.submenu {
					left: auto;
					right: 0;

					> li.has-submenu {
						.submenu {
							left: auto;
							right: 100%;
							margin-left: 0;
							margin-right: 10px;
						}
					}
				}
			}
			> ul {
				> li {
					&.has-submenu {
						&:active,
						&:hover,
						&.show {
							> a {
								color: $gray-200;
							}
						}
					}
				}
			}

			.submenu {
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 1000;
				padding: 10px 0;
				list-style: none;
				min-width: 200px;
				text-align: left;
				visibility: hidden;
				opacity: 0;
				margin-top: 10px;
				border-radius: 0 0 4px 4px;
				transition: all 0.2s ease;
				background-color: lighten($bg-topbar-dark, 5%);

				// &:before {
				//     left: 16px;
				//     top: -10px;
				//     content: "";
				//     display: block;
				//     position: absolute;
				//     background-color: transparent;
				//     border-left: 12px solid transparent;
				//     border-right: 12px solid transparent;
				//     border-bottom: 12px solid lighten($bg-topbar-dark,5%);
				//     z-index: 9999;
				// }

				li {
					position: relative;

					ul {
						list-style: none;
						padding-left: 0;
						margin: 0;
					}

					a {
						display: block;
						padding: 9px 25px;
						clear: both;
						white-space: nowrap;
						color: $gray-500;

						&:hover {
							color: $gray-300;
						}
					}

					&.has-submenu {
						> a {
							.arrow-down {
								&:after {
									right: 20px !important;
									transform: rotate(-135deg) translateY(-50%) !important;
									position: absolute;
								}
							}
						}
					}

					.submenu {
						left: 100%;
						top: 0;
						margin-left: 20px;
						margin-top: -1px;

						&:before {
							left: -20px;
							top: 10px;
							border-top: 12px solid transparent;
							border-bottom: 12px solid transparent;
							border-right: 12px solid lighten($bg-topbar-dark, 5%);
						}
					}
				}

				&.megamenu {
					white-space: nowrap;
					width: auto;

					> li {
						overflow: hidden;
						width: 200px;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}
	}

	.navbar-toggle {
		display: none !important;
	}

	#navigation {
		display: block !important;
		max-height: 70px;
	}
}

@media (min-width: 700px) {
	.navigation-menu,
	.topnav-menu {
		> li,
		.notification-list {
			&.has-submenu {
				&:hover, //TODO: remove this
                &.show {
					> .submenu,
					> .dropdown-menu {
						visibility: visible !important;
						opacity: 1 !important;
						margin-top: 0 !important;
						display: block !important;

						> li {
							&.has-submenu {
								&.show {
									> .submenu {
										visibility: visible;
										opacity: 1;
										margin-left: 10px;
										margin-right: 0;
									}
								}
							}
						}
					}
				}
				&.last-elements {
					> .submenu {
						&:before {
							right: 11px;
							left: auto;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 699px) {
	.wrapper {
		padding-top: $topbar-height;
	}

	#topnav {
		.navigation-menu {
			float: none;
			max-height: 400px;
			text-align: left;

			> li {
				display: block;
				float: none;

				> a {
					color: $gray-700;
					padding: 15px 24px;
					i {
						display: inline-block;
						margin-right: 10px;
						margin-bottom: 0;
						vertical-align: inherit;
					}

					&:after {
						position: absolute;
						right: 15px;
					}

					&:hover,
					&:focus,
					&:active,
					&.show {
						color: $white;
						background-color: #adb5bd;
					}
				}

				.submenu {
					display: none;
					list-style: none;
					padding-left: 20px;
					margin: 0;

					li {
						a {
							display: block;
							position: relative;
							padding: 7px 20px;
							color: $gray-600;

							&:hover {
								color: $primary;
							}
						}
					}
					&.open {
						display: block;
					}

					.submenu {
						display: none;
						list-style: none;

						&.open {
							display: block;
						}
					}
					&.megamenu {
						> li {
							> ul {
								list-style: none;
								padding-left: 0;
							}
						}
					}
				}
				&.has-submenu.open > a {
					color: $primary;
				}
			}
		}

		.has-submenu {
			&.active {
				a {
					color: $primary;
				}
				.submenu {
					li.active > a {
						color: $primary;
					}
				}
			}
		}
		.arrow-down {
			&:after {
				right: 24px;
				position: absolute;
			}
		}
		.navbar-header {
			float: left;
		}
	}
	#navigation {
		position: absolute;
		top: 70px;
		left: 0;
		width: 100%;
		display: none;
		height: auto;
		padding-bottom: 0;
		overflow: auto;
		box-shadow: $shadow;
		background-color: $white;

		&.open {
			display: block;
			overflow-y: auto;
		}
	}
}

@media (max-height: 375px) {
	.navigation-menu {
		max-height: 240px !important;
	}
}

// Light Menubar
.menubar-light {
	#topnav {
		background: $bg-topbar-light;
		box-shadow: $shadow;

		// Active Menu color
		.has-submenu {
			&.active {
				> a {
					color: $primary;
				}
				.submenu {
					li.active > a {
						color: $white;
					}
				}
			}
		}
	}

	.navigation-menu {
		> li {
			> a {
				color: $gray-700;
			}
		}
	}
}

// Center Menu

.center-menu {
	@media (min-width: 700px) {
		#topnav {
			.navigation-menu {
				text-align: center;

				> li {
					display: inline-block;
					float: none;

					> a {
						padding: 20px;

						i {
							display: block;
							margin: 0 0 5px 0;
							float: none;
							text-align: center;
						}

						.arrow-down {
							display: none;
							margin: 0 0 5px 0;
						}
					}
				}
			}
		}
		.wrapper {
			padding: ($topbar-height + 80px) ($grid-gutter-width / 2) 0 ($grid-gutter-width / 2);
		}
	}
}

// Gradient Menu
.gradient-topbar {
	#topnav {
		background: $bg-topbar-gradient;
		box-shadow: $shadow-lg;
	}

	&.topbar-dark {
		.navbar-custom {
			background-color: transparent !important;
			box-shadow: none;
		}
	}
}

// Drop Menu Light
.drop-menu-light {
	@media (min-width: 700px) {
		#topnav {
			.has-submenu {
				&.active {
					.submenu {
						li.active > a {
							color: $primary;
						}
					}
				}
			}
		}
		.navigation-menu {
			> li {
				.submenu {
					background-color: $white;

					&:before {
						border-bottom: 12px solid $white;
					}

					li {
						a {
							color: $gray-600;

							&:hover {
								color: $primary;
							}
						}

						.submenu {
							&:before {
								border-right: 12px solid $white;
							}
						}
					}
				}

				> ul {
					> li {
						&.has-submenu {
							&:active,
							&:hover,
							&.show {
								> a {
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Unsticky Header
.unsticky-header {
	@media (min-width: 700px) {
		#topnav,
		.navbar-custom {
			position: absolute;
		}
	}
}

// Boxed layout
.boxed-layout {
	.container-fluid {
		max-width: $boxed-layout-width;
	}
}

// brane fixes
.container-fluid.navbar-fix {
	max-width: inherit;
}
.navbar-custom.navbar-fix {
	padding-left: 0;
	padding-right: 0;
}
@media (min-width: 700px) {
	.navbar-fix > .container-fluid {
		padding-left: 4px;
	}
}
@media (min-width: 1200px) {
	.navbar-fix > .container-fluid {
		max-width: 1220px;
	}
}