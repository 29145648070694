
.close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: $light;


    .custombox-,
    .custombox-modal-flash,
    .custombox-modal-rotatedown & {
        top: 20px;
        z-index: 9999;
    }

}
