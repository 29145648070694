a,
a:hover {
    .dashboard & {
        cursor: pointer;
    }
    .projects & {
        cursor: pointer;
    }
}

.checkbox {
    label {
        cursor: pointer;
    }
}

.dropdown-menu {
    cursor: pointer;
}
.dropdown-item:hover {
    color: #fff !important;
}

.CircularProgressbar.customProgressbar {
    width: 120px;
    height: 120px;
}

.CircularProgressbar {
    &.customProgressbar {
        .CircularProgressbar-text {
            dominant-baseline: central;
        }
    }
}

.camera-setting {
    &:nth-child(2n) {
        text-align: center !important;
    }

    &:nth-child(3n) {
        text-align: right !important;
    }
}

.ck {
    color: #000;
}

.dd-drop-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 200px;
    border: 4px dashed #adb5bd;

    &.disabled {
        border: 4px dashed #6c747c;
        color: #6c747c;
    }

    &.active {
        border: 4px dashed #ff7f29;
        color: #ff7f29;
    }

    .dd-upload-icon {
        font-size: 45px;
    }

    .dd-drop-message {
        text-align: center;
        font-size: 20px;
    }

    .dd-hide-file {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        height: 100% !important;
        width: 100%;
    }
}

.dd-image-preview {
    width: 350px;
}

.cursor-pointer {
    cursor: pointer;
}

div.rendered-react-keyed-file-browser div.action-bar {
    display: block !important;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.push-right {
    display: flex;
    flex-direction: row-reverse;
}

.code {
    color: $code-color;
}

.btn-small {
    padding: 3px 6px;
}

.p-0 {
    padding: 0;
}

.pb-0 {
    padding-bottom: 0;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-10 {
    padding-bottom: 10px;
}

.pt-0 {
    padding-top: 0;
}
.pt-20 {
    padding-top: 20px;
}
.pt-10 {
    padding-top: 10px;
}

.bold {
    font-weight: bold;
}

.checkrow {
    background-color: #303841;
    padding: 10px 10px 5px 10px;
    border-radius: 3px;

    & > div {
        display: inline-block;
        position: relative;
        margin-bottom: 0;

        width: 16px;
        height: 16px;

        border-radius: 3px;
        border: 2px solid lighten($gray-700, 7%);
    }

    .checked {
        position: relative;
        top: 1px;
        left: 4px;
        width: 4px;
        height: 8px;
        border: 2px solid $gray-500;
        border-top-width: 0;
        border-left-width: 0;
        transform: rotate(45deg);
    }

    &.checkrow-floating {
        position: absolute;
        z-index: 1001;

        top: 15px;
        left: 15px;
    }
}

@each $color, $value in $theme-colors {
    .checkrow-#{$color} .checked-color {
        background-color: $value;
        border-color: $value;
    }
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.lh-35 {
    line-height: 35px;
}

.mb-20 {
    margin-bottom: 20px;
}

.custom-control {
    .custom-control-label::before {
        background-color: #515e6b;
    }
}
