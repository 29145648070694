//
// footables.scss
//

@font-face {
    font-family: 'footable';
    src: url('/assets/fonts/footable.eot');
    src: url('/assets/fonts/footable.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/footable.woff') format('woff'), url('/assets/fonts/footable.ttf') format('truetype'), url('/assets/fonts/footable.svg#footable') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'footable';
        src: url('/assets/fonts/footable.svg#footable') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}

.footable-odd {
    background-color: $gray-800;
}

.footable-row-detail,
.footable-detail-show {
    background-color: lighten($gray-800,3%);
}

.footable-pagination {
    li {
        margin-left: 5px;
        float: left;
        a {
            position: relative;
            display: block;
            padding: .5rem .75rem;
            margin-left: -1px;
            line-height: 1.25;
            color: $dark;
            background-color: $pagination-bg;
            border: $pagination-border-width solid $pagination-border-color;
            border-radius: 2px;
        }
    }
    li.active {
        a {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }
    }
}

.footable>thead>tr>th>span.footable-sort-indicator {
    float: right;
}

.footable-toggle {
    color: $gray-500 !important;
    margin-right: 5px;
}