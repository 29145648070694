//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
}

//
// Brane fixes
//

/* Slightly better dropdowns */
select {
    option[disabled] {
        display: none !important;
    }
}

/* Fuck you IOS (border around links) */
a:hover,
a:active,
a:focus {
    outline: none !important;
}

/* PIN input */
.pin {
    h1 {
        font-family: monospace;
        font-size: 1rem;
        letter-spacing: 0.25rem;
    }
    button.keypad {
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;
        line-height: 1;
        padding: 1rem;
        clip-path: circle(50%); /* Fuck you IOS */
        &:focus {
            box-shadow: none;
            -webkit-box-shadow: none;
        }
        &:hover {
            color: initial;
            background-color: initial;
            border-color: #485561;
        }
        &.backspace {
            border: none !important;
        }
    }
    .modal-title {
        text-align: center !important;
    }
}

/* Absolute center */
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-backdrop.show {
    display: none !important;
}

/* Visibility hidden class - because we lazy */
.hidden {
    visibility: hidden !important;
}

/* No text selection - let's behave like an actual app */
*:not(input) {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

/* Toast fixes */
.toast {
    display: none;
    // &.show {
    // 	display: flex;
    // }
}

/* Toast fatal */
.toast-fatal {
    display: flex;
    display: none;
}
.toast-fatal,
.toast-fatal.show {
    position: absolute;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    -webkit-border-radius: 0;
    z-index: 9000;
    // display: flex;
}

/* General modal improvements */
.modal-demo {
    z-index: 9998;
    h4 {
        border-radius: 4px 4px 0 0;
        -moz-border-radius: 4px 4px 0 0;
    }
    button {
        z-index: 9999;
    }

    &.modal-photo > .modal-dialog {
        min-width: 200px !important;

        padding: 0;
        img,
        canvas {
            max-width: 90vw;
            max-height: calc(95vh); // https://bugs.webkit.org/show_bug.cgi?id=141832 - when developers are too smart for their own good
            object-fit: contain;
            vertical-align: middle;
        }
    }
}

/* Appended form buttons fixes */
.btn-secondary.no-af:active,
.btn-secondary.no-af:focus {
    box-shadow: inherit !important;
    -webkit-box-shadow: inherit !important;
    background-color: #485561 !important;
    border-color: #485561 !important;
}

/* Disabled inputs fix */
.custom-select:disabled,
input:disabled,
.input-group-append button:disabled {
    color: #6c757d;
    background-color: #404d58;
}
.input-group-append button:disabled {
    border-left-color: transparent;
    opacity: 1;
}

/* Dropdown fixes */
.dropdown.notification-list,
.dropdown.notification-list a {
    &:hover,
    &:focus,
    &:active,
    &.show {
        color: $white;
        background-color: rgba(72, 85, 97, 0.7);
    }
}
.topnav-menu .dropdown.notification-list a,
.dropdown-item {
    &:hover,
    &:focus,
    &:active,
    &.show {
        color: $white;
        background-color: initial;
    }
}

.dropdown,
.dropdown-menu {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

// Floating top buttons on cards better alignment
.card-top-buttons-align {
    margin-top: -0.2rem;
}

// Inline widget kao
.inline-widget {
    // background: #475560;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
}

// Notification list on full page
.card-box .notification-list .notify-item {
    border-bottom: 1px solid #3c4853;
    overflow: visible;
    padding: 0.625rem 0;
    position: relative;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
    .notify-details {
        margin-bottom: 0;
        overflow: visible;
        white-space: normal;
    }
}

// Fucking canvas/js/neshto knob
.knob-wrapper {
    position: relative;
    svg {
        transform: scaleY(-1);
    }
    .knob-inside-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        padding: 0;
        margin: 0;
        h3 {
            line-height: 1;
        }
    }
}

// Multiselect
.bootstrap-select {
    button {
        color: #f7f7f7 !important;
        background: $custom-select-background !important;
        background-color: #3c4853 !important;
    }
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    &.show > .btn-secondary.dropdown-toggle {
        border-color: #4c5a67 !important;
        background-color: #414d59 !important;
    }
    .dropdown-toggle {
        &:before {
            content: '' !important;
            display: none !important;
        }
        &:focus {
            outline: none !important;
            outline-offset: 0;
        }
    }
}

@keyframes loading {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

// Data loading
.data-waiting {
    transition: opacity 0.2s ease-in-out !important;
    &:before {
        content: '';
        position: absolute;
        width: calc(100% - 1.5rem);
        height: calc(100% - 1.5rem);
        animation-duration: 1.8s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loading;
        animation-timing-function: linear;
        background-color: #36404a;
        background: linear-gradient(to right, #36404a 0%, #3e4a56 20%, #36404a 35%);
        background-size: 200% 100%;
        top: 0;
        left: 0.75rem;
        border-radius: 0.25rem;
        z-index: 500;
        opacity: 1 !important;
        transition: opacity 0.2s ease-in-out !important;
    }
}
.data-loaded {
    transition: opacity 0.2s ease-in-out !important;
    &:before {
        content: '';
        position: absolute;
        width: calc(100% - 1.5rem);
        height: calc(100% - 1.5rem);
        animation-duration: 1.8s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loading;
        animation-timing-function: linear;
        background-color: #36404a;
        background: linear-gradient(to right, #36404a 0%, #3e4a56 20%, #36404a 35%);
        background-size: 200% 100%;
        top: 0;
        left: 0.75rem;
        border-radius: 0.25rem;
        z-index: 0;
        opacity: 0 !important;
        transition: opacity 0.2s ease-in-out !important;
    }
}

.widget-inline {
    // Show widget title while loading data
    // h1, h2, h3, h4, h5, h6 {
    // 	z-index: 9001;
    // 	position: relative;
    // }
    // Fix for floated elements not clickable when loading div is in use
    .float-right {
        position: relative;
    }
}

/* Table */
.table {
    thead {
        th {
            border-top: none;
            border-bottom: none;
        }
    }
}

/* Table */
.table {
    thead {
        th {
            border-top: none;
            border-bottom: none;
        }
    }
}

/* Proper esktop cursors */
a.dropdown-toggle,
i.mdi-help-circle-outline,
a.action-icon {
    cursor: pointer;
}
