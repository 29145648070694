.modal {
    background-color: #36404a66;
    // width: 600px !important;
    border-radius: 4px;
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1055;

    &.full {
        width: 92%;
        max-width: none;
    }

    @include mq('bp_768') {
        width: 100% !important;
    }

    .modal-wrapper {
        text-align: left;
    }

    .modal-text {
        padding: 20px;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        color: $light;
    }


    .custombox-
    .custombox-modal-flash,
    .custombox-modal-rotatedown {
        .close {
            top: 20px;
            z-index: 9999;
        }
    }
}

